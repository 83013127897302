import { Grid, Typography } from '@mui/material';

import { Page } from '@/types/generated';
import { ComponentResolver } from '@/components/ComponentResolver';
import { ImagePriorityContext } from '@/utils/images/imageOptimization';
interface INextPage {
	pageData: Page;
}

export default function NextPage({ pageData }: INextPage) {
	const { contentsCollection, pageTitle } = pageData;

	return (
		<>
			{pageTitle ? (
				<Grid container alignItems="center" wrap="nowrap">
					<Typography variant="header1" color="primary" data-test-id="text_page_title">
						{pageTitle}
					</Typography>
				</Grid>
			) : null}

			{contentsCollection?.items?.map((content, idx) => {
				if (!content?.__typename || Object.keys(content).length <= 2) {
					console.warn(
						`⚠️⚠️⚠️ Shared Components Web - No content __typename found for ${
							content?.sys?.id || 'Unknown entryId'
						} ⚠️⚠️⚠️`
					);
					return null;
				}

				return (
					<ImagePriorityContext.Provider key={`${content.sys.id}-${content.__typename}-${idx}`} value={idx}>
						<ComponentResolver data-index={idx} componentProps={content} typename={content.__typename} />
					</ImagePriorityContext.Provider>
				);
			}) ?? null}
		</>
	);
}
